import { combineReducers } from 'redux';
import home from './home';
import alert from './alert';
import auth from './auth';
import dataroom from './dataroom';

const rootReducer = combineReducers({
  auth,
  alert,
  home,
  dataroom,
});

export default rootReducer;
