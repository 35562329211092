export const GET_FEATURED_SYMBOL_DATA = 'GET_FEATURED_SYMBOL_DATA';
export const GET_FEATURED_SYMBOL_DATA_ERROR = 'GET_FEATURED_SYMBOL_DATA_ERROR';
export const GET_DATAROOM_TIMESERIES = 'GET_DATAROOM_TIMESERIES';
export const GET_DATAROOM_TIMESERIES_ERROR = 'GET_DATAROOM_TIMESERIES_ERROR';
export const GET_DATAROOM_HEADLINES = 'GET_DATAROOM_HEADLINES_ERROR';
export const GET_DATAROOM_HEADLINES_ERROR = 'GET_DATAROOM_HEADLINES_ERROR';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_UPDATED = 'ACCOUNT_UPDATED';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
