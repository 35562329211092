import {
  GET_DATAROOM_TIMESERIES,
  GET_DATAROOM_TIMESERIES_ERROR,
  GET_DATAROOM_HEADLINES,
  GET_DATAROOM_HEADLINES_ERROR,
} from '../actions/types';

const initialState = {
  timeseries: [],
  headlines: [],
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DATAROOM_TIMESERIES:
      return {
        ...state,
        timeseries: payload,
        loading: false,
      };
    case GET_DATAROOM_HEADLINES:
      return {
        ...state,
        headlines: payload,
        loading: false,
      };
    case GET_DATAROOM_TIMESERIES_ERROR:
    case GET_DATAROOM_HEADLINES_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
